import { extendTheme, theme as baseTheme } from "@chakra-ui/react";

/* 
https://chakra-ui.com/docs/getting-started#add-custom-theme-optional
https://chakra-ui.com/docs/theming/theme
https://chakra-ui.com/docs/theming/customize-theme#using-theme-extensions
*/

const theme_modifications = {
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/typography.ts
  fonts: {
    body: `"Open Sans", system-ui, sans-serif`,
    heading: `"Pinyon Script", Georgia, serif`,
    mono: "Menlo, monospace",
  },
  textStyles: {
    feminine: {
      fontFamily: "heading",
    },
  },
  // https://chakra-ui.com/docs/theming/customize-theme#customizing-global-styles
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts
  styles: {
    global: {
      body: {
        bg: "#F6F8F9",
      },
    },
  },
};

const theme_modifications_config = {
  // https://chakra-ui.com/docs/theming/theme#config
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/index.ts
  config: {
    useSystemColorMode: false,
    initialColorMode: "light",
    cssVarPrefix: "sl",
  },
};

export default extendTheme(
  theme_modifications,
  theme_modifications_config,
  baseTheme
);
